import React from "react";
import ReactDOM from "react-dom";

const subsribeStates = {
    DEFAULT: 'default',
    SUBMITTING: 'submitting',
    SUCCESS: 'success',
    FAILURE: 'failure'
};

class Subscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscribeEmail: '',
            screenstate: subsribeStates.DEFAULT
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email));
    }

    handleChange(event) {
        this.setState({ screenstate: subsribeStates.DEFAULT, subscribeEmail: event.target.value });
        $('.subscribe-buttons').hide();
        $('#subscribe-submit').show();
    }

    handleClick(event) {
        // Prevent the default action from executing.
        event.preventDefault();

        var callingForm = this;
        if (this.validateEmail(this.state.subscribeEmail.toLocaleLowerCase())) {
            // Set the internal component state to submitting.
            this.setState({ state: subsribeStates.SUBMITTING });

            // Since state updates are async directly set the visual queue.
            $('.subscribe-buttons').hide();
            $('#subscribe-submitting').show();

            // Post the subription request.
            fetch('/insights/subscribe', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(callingForm.state.subscribeEmail)
            })
                .then(function (response) {
                    // Check if the response was success.
                    if (response.status === 200) {
                        // Update the component internal state and give immediate ui feedback. 
                        callingForm.setState({ screenstate: subsribeStates.SUCCESS });
                        $('.subscribe-buttons').hide();
                        $('#subscribe-success').show();

                        // After 3 seconds reset the entire form.
                        setTimeout(function () {
                            callingForm.setState({ screenstate: subsribeStates.DEFAULT, subscribeEmail: '' });
                            $('.subscribe-buttons').hide();
                            $('#subscribe-submit').show();
                        }, 3000);
                    }
                    else {
                        callingForm.setState({ screenstate: subsribeStates.FAILURE });
                        $('.subscribe-buttons').hide();
                        $('#subscribe-failure').show();
                        setTimeout(function () {
                            callingForm.setState({ screenstate: subsribeStates.DEFAULT });
                            $('.subscribe-buttons').hide();
                            $('#subscribe-submit').show();
                        }, 3000);
                    }
                    setTimeout(function () {
                        callingForm.setState({ screenstate: subsribeStates.DEFAULT });
                    }, 3000);
                })
                .catch(function () {
                    callingForm.setState({ state: screenStates.FAILURE });
                    $('.subscribe-buttons').hide();
                    $('#subscribe-failure').show();
                    setTimeout(function () {
                        callingForm.setState({ screenstate: subsribeStates.DEFAULT });
                        $('.subscribe-buttons').hide();
                        $('#subscribe-submit').show();
                    }, 3000);
                });
        }
        else {
            $('.subscribe-buttons').hide();
            $('#subscribe-validation').show();
            setTimeout(function () {
                callingForm.setState({ screenstate: subsribeStates.DEFAULT });
                $('.subscribe-buttons').hide();
                $('#subscribe-submit').show();
            }, 3000);
        }
    }

    render() {
        return (
            <div>
                <div id="subscribe-submit" className="subscribe-buttons">
                    <form className="form-inline subscribe-form-js" method="post">
                        <input name="email" placeholder="Enter your email address" type="email" value={this.state.subscribeEmail} onChange={this.handleChange} />
                     <button className="btn btn--green-light" onClick={e => this.handleClick(e)}>
                            Subscribe
                    </button>
                    </form>
                </div>
                <div id="subscribe-validation" className="subscribe-buttons" style={{ display: 'none' }}>
                    <h6 className="iq-tw-6 iq-pall-10">Please enter a valid email...</h6></div>
                <div id="subscribe-submitting" className="subscribe-buttons" style={{ display: 'none' }}>
                    <h6 className="iq-tw-6 iq-pall-10">Please wait a second, submitting the request...</h6></div>
                <div id="subscribe-success" className="subscribe-buttons" style={{ display: 'none' }}>
                    <h6 className="iq-tw-6 iq-pall-10">Success! Speak to you soon...</h6></div>
                <div id="subscribe-failure" className="subscribe-buttons" style={{ display: 'none' }}>
                    <h6 className="iq-tw-6 iq-pall-10">Something went wrong! Try again.</h6></div>
            </div>
        );
    }
}

const containerElement = document.getElementById('subscribe-app');
ReactDOM.render(<Subscribe />, containerElement);